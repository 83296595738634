import React from "react";
import LaunchingSoon from "../../components/launching-soon/launching-soon";

const Home = () => {
    return (
        <div>
            <LaunchingSoon />
        </div>
    )
}

export default Home;