import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import RentOutBg from "../../images/rent-out-bg.webp";

const ParkingAdSection = () => {
    return (
        <Container fluid className="bg-light text-center" style={{ padding: '80px 0', background: 'linear-gradient(135deg, #F2E5D5, #FFFFFF)' }}>
            <Row className="align-items-center justify-content-center flex-column-reverse flex-md-row" style={{ padding: '0 30px', borderRadius: '10px' }}>
                <Col md={6}>
                    <h1 style={{ color: '#333333', fontWeight: 'bold' }}>Book a parking space for your daily or monthly needs</h1>
                    <p style={{ fontSize: '18px', color: '#B3B3B3' }}>
                        Find and reserve a parking spot that fits your schedule. Whether you need a space for a day or for the entire month, we’ve got you covered. Booking is quick, easy, and flexible to suit your lifestyle.
                    </p>
                    <Button variant="success" size="lg" style={{ fontWeight: 'bold', backgroundColor: '#00B8A9', border: 0 }}>Start booking your spot today</Button>
                </Col>
                <Col md={6} className="d-md-block">
                    <img src={RentOutBg} alt="Parking spaces in front of houses" className="img-fluid pb-3 md-pb-0" style={{ borderRadius: '10px' }} />
                </Col>
            </Row>
        </Container>
    );
};

export default ParkingAdSection;
