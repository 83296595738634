import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const LatestBlogs = () => {
    const [blogs, setBlogs] = useState([
        {
            id: 1,
            title: 'Finding and Booking Parking in Dubai',
            excerpt: 'Discover easy and affordable parking solutions in Dubai with GCCpark. Find and book parking spaces with ease. Monthly and daily options available.',
            image: `${process.env.PUBLIC_URL}/blog/images/finding-blog-image.webp`,
            link: "finding-and-booking-parking-in-dubai"
        },
        {
            id: 2,
            title: 'Best Parking Spots for Tourists in Dubai',
            excerpt: "Dubai, a city renowned for its luxurious lifestyle and stunning skyline, can be quite challenging when it comes to finding parking, especially for tourists",
            image: `${process.env.PUBLIC_URL}/blog/images/best-tourism.jpg`,
            link: "best-parking-spots-for-tourists-in-dubai"
        },
        {
            id: 3,
            title: 'Dubai International Airport Parking',
            excerpt: "Your ultimate guide to parking at Dubai International Airport. Discover the various parking options, tips for booking, and how to make your airport parking experience seamless and stress-free.",
            image: `${process.env.PUBLIC_URL}/blog/images/Dubai-Airport-Car-Parking.jpg`,
            link: "dubai-international-airport-parking"
        },
    ]);

    return (
        <section style={{ backgroundColor: '#F2E5D5', padding: '40px 0' }}>
            <div className="container mt-0">
                <h2 style={{ color: '#2A4E5C', textAlign: 'center', marginBottom: '30px' }}>Latest Blog Posts</h2>
                <Row>
                    {blogs.map((blog, index) => (
                        <Col key={index} md={4} className="mb-4">
                            <Card style={{ borderColor: '#B3B3B3', backgroundColor: '#FFFFFF' }}>
                                <Card.Img variant="top" src={blog.image} alt={blog.title} />
                                <Card.Body>
                                    <Card.Title style={{ color: '#2A4E5C', fontWeight: 800 }}>{blog.title}</Card.Title>
                                    <Card.Text style={{ color: '#5a5a5a' }}>{blog.excerpt}</Card.Text>
                                    <Button variant="outline-dark" style={{ backgroundColor: '#F15A29', borderColor: '#F15A29', color: '#FFFFFF' }} href={`/blog/${blog.link}`}>
                                        Read More
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    );
};

export default LatestBlogs;
