import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Define custom marker icons if needed
const destinationIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const carparkIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const containerStyle = {
  width: '1200px',
  height: '400px',
  margin: '0px auto',
};

function LeafletMapComponent(props) {
  const { lat, lng, carparks } = props;
  const center = [lat, lng];

  return (
    <div style={containerStyle}>
      <MapContainer center={center} zoom={14} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Render Carpark Markers */}
        {carparks.map(({ lat, lng, label, url }) => (
          <Marker key={label} position={[lat, lng]} icon={carparkIcon}>
            <Popup>
              <a href={url} target="_blank" rel="noopener noreferrer">{label}</a>
            </Popup>
          </Marker>
        ))}

        {/* Destination Marker */}
        <Marker position={center} icon={destinationIcon}>
          <Popup>Destination</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default React.memo(LeafletMapComponent);
