import React from "react";
import AuthForm from "../../components/form/AuthForm";
import { Card, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import "./login.css";

const Login = () => {
    const [option, setOption] = React.useState(1);

    return (
        <div className="login-container d-flex justify-content-center align-items-center">
            <Card className="p-4 shadow-sm login-card">
                <h3 className='text-center mb-4'>Login Form</h3>
                <ToggleButtonGroup
                    type="radio"
                    name="options"
                    value={option}
                    className="w-100 mb-3"
                    onChange={setOption}
                >
                    <ToggleButton
                        variant="outline-primary"
                        value={1}
                        className={`toggle-btn ${option === 1 ? "active" : ""}`}
                        onClick={() => setOption(1)}
                    >
                        Login
                    </ToggleButton>
                    <ToggleButton
                        variant="outline-primary"
                        value={2}
                        className={`toggle-btn ${option === 2 ? "active" : ""}`}
                        onClick={() => setOption(2)}
                    >
                        Signup
                    </ToggleButton>
                </ToggleButtonGroup>
                <AuthForm option={option} setOption={setOption} />
            </Card>
        </div>
    );
};

export default Login;
