import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Tabs,
    Tab,
} from "react-bootstrap";
import "./search.css";
import { Fragment } from "react";
import locations from "../../data/locations.json";

const Search = () => {
    const navigate = useNavigate();
    const [destination, setDestination] = useState("");
    const [arrivingDate, setArrivingDate] = useState("");
    const [leavingDate, setLeavingDate] = useState("");
    const [activeTab, setActiveTab] = useState("monthly");

    const handleSearch = () => {
        const selectedLocation = locations.data.find(
            (loc) => loc.name === destination
        );

        if (!selectedLocation) {
            alert("Please select a location.");
            return;
        }

        if (!arrivingDate || !leavingDate) {
            alert("Please select both a start and end date.");
            return;
        }

        const startDate = new Date(arrivingDate);
        const endDate = new Date(leavingDate);

        if (startDate > endDate) {
            alert("Start date cannot be later than the end date.");
            return;
        }

        // Check if the difference between dates exceeds one month (30 days)
        const diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
        if (diffInDays > 30) {
            alert("The maximum duration between the start and end date is one month.");
            return;
        }

        const { name, slug } = selectedLocation;
        setDestination(name);

        navigate(`/locations/${slug}`, {
            state: {
                destination: name,
                arrivingDate,
                leavingDate
            },
        });
    };


    return (
        <Container fluid className="px-0">
            <div className="search-session">
                <div className="search-details">
                    <h3 className="text-center" style={{ padding: '10px 20px', margin: 0, display: 'flex', gap: '5px', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <span>
                            The
                        </span>
                        <span style={{ color: "#F15A29" }}>
                            Fastest
                        </span>
                        <span>
                            and
                        </span>
                        <span style={{ color: "#F15A29" }}>
                            Most Affordable
                        </span>
                        <span>
                            Way To Find Parking
                        </span>
                    </h3>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(tab) => setActiveTab(tab)}
                        className="tabs-search-container"
                        fill
                        justify
                    >
                        <Tab eventKey="monthly" title="Monthly" className="bg-light" active={true}>
                            <SearchForm
                                staticLocations={locations.data}
                                setDestination={setDestination}
                                arrivingDate={arrivingDate}
                                setArrivingDate={setArrivingDate}
                                leavingDate={leavingDate}
                                setLeavingDate={setLeavingDate}
                                handleSearch={handleSearch}
                            />
                        </Tab>
                        <Tab
                            eventKey="hourly-daily"
                            title={<Fragment>
                                <span>Hourly/Daily</span>
                                <span className="text-soon"> Soon </span>
                            </Fragment>}
                            active={false}
                            disabled={true}
                        />
                    </Tabs>
                </div>
            </div>
        </Container>
    );
};

const SearchForm = ({
    staticLocations,
    setDestination,
    arrivingDate,
    setArrivingDate,
    leavingDate,
    setLeavingDate,
    handleSearch,
}) => (
    <Form className="pt-3">
        <Row className="align-items-center justify-content-center">
            <Col xs={12} md={12}>
                <InputGroup className="mb-3">
                    <InputGroup.Text style={{ backgroundColor: "#F15A29" }}>
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            style={{ color: "#FFFFFF" }}
                        />
                    </InputGroup.Text>
                    <Form.Select
                        onChange={(e) => setDestination(e.target.value)}
                    >
                        <option value="">Select a location</option>
                        {staticLocations.map((loc, index) => (
                            <option key={index} value={loc.name}>
                                {loc.name}
                            </option>
                        ))}
                    </Form.Select>
                </InputGroup>
            </Col>
        </Row>

        <Row className="mb-3">
            <Col xs={12} md={6} className="mb-2">
                <Form.Group controlId="arrivingDate">
                    <Form.Label style={{ color: "#333333" }}>
                        Start Date
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={arrivingDate}
                        onChange={(e) => setArrivingDate(e.target.value)}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={6}>
                <Form.Group controlId="leavingDate">
                    <Form.Label style={{ color: "#333333" }}>
                        End Date
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={leavingDate}
                        onChange={(e) => setLeavingDate(e.target.value)}
                    />
                </Form.Group>
            </Col>
        </Row>

        <div className="text-center py-3">
            <Button
                variant="primary"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "#F15A29",
                    border: 0,
                }}
                onClick={handleSearch}
            >
                Find Parking
            </Button>
        </div>
    </Form>
);

export default Search;
