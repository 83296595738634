import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import easyparkAPI from "../../config/api";

function App() {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('success')

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        try {
            const response = await easyparkAPI.post('/submit-email', { email })
            // const response = await axios.post('http://localhost:5000/api/submit-email', { email });
            setMessage(response.data.message);
            setVariant('success');
        } catch (error) {
            setMessage(error.response?.data?.message || 'An error occurred.');
            setVariant('danger');
        }
        setEmail('');
    };

    useEffect(() => {
        const navbarElement = document.getElementById('navbar');
        const footerElement = document.getElementById('footer');
        if (navbarElement && footerElement) {
            navbarElement.remove();
            footerElement.remove();
        }
    }, []);


    return (
        <Container
            fluid
            className="text-center py-5"
            style={{
                background: `linear-gradient(to bottom, #F2E5D5, #2A4E5C)`,
                color: '#333333',
                minHeight: '100vh',
            }}
        >
            <Container style={{ maxWidth: '600px' }}>
                <h1 className="display-4" style={{ color: '#2A4E5C' }}>GCCpark</h1>
                <p className="lead" style={{ color: '#333333' }}>
                    Simplifying parking by connecting drivers with available spaces through real-time booking, dynamic pricing, and cost-effective solutions.
                </p>

                <h2 className="my-4" style={{ color: '#333333' }}>Launching Soon</h2>

                {message && <Alert variant={variant}>{message}</Alert>}

                {submitted ? (
                    <Alert variant="success" style={{ backgroundColor: '#00B8A9', color: '#FFFFFF', border: 'none' }}>
                        Thank you for signing up! We'll keep you updated.
                    </Alert>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail">
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                style={{ borderColor: '#B3B3B3', color: '#333333' }}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            className="mt-3"
                            style={{
                                backgroundColor: '#F15A29',
                                borderColor: '#F15A29',
                                color: '#FFFFFF'
                            }}
                        >
                            Notify Me
                        </Button>
                    </Form>
                )}

                <hr className="my-5" style={{ borderColor: '#B3B3B3' }} />

                <Row>
                    <Col md={6}>
                        <h5 style={{ color: '#FF6B6B' }}>For Drivers</h5>
                        <p style={{ color: '#333333' }}>Find affordable and available parking spaces quickly and easily.</p>
                    </Col>
                    <Col md={6}>
                        <h5 style={{ color: '#FF6B6B' }}>For Space Owners</h5>
                        <p style={{ color: '#333333' }}>Turn your parking space into a revenue-generating asset.</p>
                    </Col>
                </Row>

                <p className="text-muted mt-4" style={{ color: '#B3B3B3' }}>&copy; 2024 GCCpark. All rights reserved.</p>
            </Container>
        </Container>
    );
}

export default App;
