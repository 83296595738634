import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SafeIcon from "../../images/safe.png";
import SaveIcon from "../../images/save.png";
import FlexIcon from "../../images/flex.png";
import "./About.css";

const About = () => {
  return (
    <Container className="about-session" fluid>
      <Row className="text-center">
        <Col lg={8} className="mx-auto">
          <h2 className="about-header">About Us</h2>
          <p className="about-text">
            Welcome to GCCpark, where we’re redefining the way you find and access parking. We believe that parking doesn’t have to be a hassle; with the right technology and a community-driven approach, it can be simple, seamless, and beneficial for everyone.
          </p>
        </Col>
      </Row>

      <Row className="section">
        <Col lg={8} className="mx-auto">
          <h3 className="about-subheader">Our Mission</h3>
          <p className="about-text">
            At GCCpark, our mission is to ease the daily challenge of parking in bustling urban areas by connecting drivers with a network of unused parking spaces. By bridging the gap between parking space owners and drivers, we’re creating a convenient, cost-effective solution that benefits property owners and drivers alike.
          </p>
        </Col>
      </Row>

      <Row className="section">
        <Col lg={8} className="mx-auto">
          <h3 className="about-subheader">What We Do</h3>
          <p className="about-text">
            Our platform enables drivers to find, book, and pay for parking spaces in real time—whether for a quick errand, daily commute, or long-term parking. GCCpark offers competitive, dynamic pricing and shows you the closest available spaces based on your location. For property owners, we provide an opportunity to turn underutilized spaces into a source of passive income.
          </p>
        </Col>
      </Row>

      <Row className="section">
        <Col lg={8} className="mx-auto">
          <h3 className="about-subheader">Why GCCpark?</h3>
          <ul className="about-list">
            <li><strong>Convenience at Your Fingertips:</strong> Access to real-time availability for parking spaces through our user-friendly app and website.</li>
            <li><strong>Environmentally Friendly:</strong> By reducing the time spent circling for parking, we help cut down on emissions, contributing to greener, more sustainable cities.</li>
            <li><strong>Future-Ready Solutions:</strong> As cities evolve, so do we. GCCpark will soon integrate air taxi stations and EV charging points, keeping pace with innovative urban mobility trends.</li>
          </ul>
        </Col>
      </Row>

      <Row className="section text-center">
        <Col lg={8} className="mx-auto">
          <h3 className="about-subheader">Join Us in Shaping the Future of Urban Parking</h3>
          <p className="about-text">
            Whether you're a driver seeking hassle-free parking or a property owner looking to maximize the value of your space, GCCpark is here to make it easy. Together, we can build smarter cities with efficient, accessible parking solutions.
          </p>
          <Button className="cta-button">Get Started</Button>
        </Col>
      </Row>

      <Row className="icon-section text-center">
        <Col md={4} className="icon-container">
          <img id="about-img" src={SafeIcon} alt="Safe" />
          <p>Reliable & Secure</p>
        </Col>
        <Col md={4} className="icon-container">
          <img id="about-img" src={FlexIcon} alt="Flexible" />
          <p>Easy To Find</p>
        </Col>
        <Col md={4} className="icon-container">
          <img id="about-img" src={SaveIcon} alt="Savings" />
          <p>Affordable!</p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
