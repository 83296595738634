import React from "react";
import Search from "../../components/search/Search";
// import RentOut from "../../components/rent-out/rent-out"
import BookingHome from "../../components/booking-home/booking-home"
import ShowingBlog from "../../components/showing-blog/showing-blog"



const Home = () => {
    return (
        <div>
            <Search />
            <BookingHome />
            <ShowingBlog />
        </div>
    )
}

export default Home;