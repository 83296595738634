import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './checkout.css';
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";

const BookingConfirmation = () => {
    const { state } = useLocation();
    const { arrivingDate, leavingDate, buildingName } = state;
    const { name, id } = useParams();

    useEffect(() => {
        console.log(state);
        console.log(name, id);
    }, [])

    return (
        <Container className="list-container" fluid>
            <Container className="py-5">
                <Row>
                    <Col md={8}>
                        <Card className="booking-details-card">
                            <Card.Body>
                                <h3 className="section-title">Confirm your booking and pay</h3>
                                <hr />

                                <h5 className="subsection-title">Booking details {buildingName}</h5>
                                <Row className="mt-3">
                                    <Col xs={6} className="mb-3">
                                        <strong>Starting date</strong>
                                        <div>{dayjs(arrivingDate).format("YYYY-MM-DD")}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>End date</strong>
                                        <div>{dayjs(leavingDate).format("YYYY-MM-DD")}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>Billing frequency</strong>
                                        <div>Monthly</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>Parking Access hours</strong>
                                        <div>7 days a week 24h a day</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>First payment</strong>
                                        <div>Today</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>Subsequent payments date</strong>
                                        <div>{dayjs(arrivingDate).format("DD")}th of each month</div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="summary-card">
                            <Card.Img
                                variant="top"
                                src="https://via.placeholder.com/400x200" // Replace with the Google Map or related image URL
                                alt="Map location"
                            />
                            <Card.Body>
                                <Card.Title className="summary-title">Parking on Arley Moss Road, M13</Card.Title>
                                <div className="text-warning mb-3">
                                    ★★★★★ (42)
                                </div>
                                <h4 className="price-text">800 AED / Monthly</h4>
                                <div className="availability-text mb-3">Availability rechecked in: <strong>12:00</strong></div>
                                <Button variant="primary" className="confirm-button">Confirm and Pay</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default BookingConfirmation;

// BookingConfirmation.css